import type { Component } from 'vue';

export interface Notification {
	id: string;
	component: Component;
	props?: Record<string, unknown>;
}

const notifications = ref<Notification[]>([]);

function add(
	component: Component,
	options: { props?: Record<string, unknown>; unique?: boolean; duration?: number } = {
		props: {},
		unique: true,
	}
) {
	if (
		options.unique &&
		notifications.value.some((notification) => notification.component.__name === component.__name)
	) {
		return;
	}

	const id = Date.now().toString();
	notifications.value.push({ component: shallowRef(component), id: id, props: options.props });
	if (options.duration) setTimeout(() => remove(id), options.duration);
}

function remove(id: string) {
	notifications.value = notifications.value.filter((notification) => notification.id !== id);
}

export function useNotification() {
	return { notifications, add, remove };
}
