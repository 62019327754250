// autocomplete, dropdown, taglist
export const dropdown = {
	inner: 'relative flex items-center border border-gray-300 focus-within:ring-blue-500 focus-within:ring-2 rounded-md',
	input: 'grow px-3 border-none outline-hidden min-w-0 text-ellipsis text-gray-700 focus:ring-0',
	listboxButton: 'self-stretch text-$scale flex items-center text-gray-700 z-10 data-[disabled]:cursor-not-allowed',
	removeSelection: 'self-stretch text-xs flex items-center text-gray-700 hover:text-red-400 z-10',
	controlLabel: 'absolute opacity-0 pointer-events-none text-[0px]',
	selectIcon: 'text-xs inline-flex justify-center w-[2.5em] relative my-auto',
	closeIcon: 'relative m-auto',
	prefixIcon: '!ml-2 !mr-0 text-gray-600',
	suffixIcon: '!mr-2 !ml-0 text-gray-600',
	dropdownWrapper:
		'rounded-md mt-1 overflow-clip empty:hidden border border-gray-300 group-data-[expanded]:opacity-100 group-data-[overscroll]:m-0 group-data-[overscroll]:shadow-none group-data-[overscroll]:border-none',
	listitemGroup: 'group/optgroup last:pb-0 border-t border-b -mb-px border-gray-300',
	// TODO: Ensure consistency with ArkUI dropdown/select
	groupLabel: 'block pt-2 pb-1 px-2 font-bold pointer-events-none text-gray-700',
	// TODO: Ensure consistency with ArkUI dropdown/select
	listbox: 'bg-white rounded-md empty:hidden',
	// TODO: Ensure consistency with ArkUI dropdown/select
	listitem: 'relative flex items-center px-3 py-2',
	// TODO: Ensure consistency with ArkUI dropdown/select
	selectedIcon: 'flex absolute items-center text-blue-500 text-xs',
	selectionWrapper: 'grow flex items-center text-gray-700',
	selection: 'grow text-gray-700 group-data-[multiple]:p-2',
	tagsWrapper: 'w-full',
	tagWrapper: 'group/tag rounded-md mr-2',
	tags: 'inline-flex flex-wrap items-center empty:mb-0',
	tag: 'relative flex items-center cursor-default rounded-md text-sm px-2 py-1 bg-blue-500 text-white [&>[type=button]]:aspect-[1/1] [&>[type=button]]:!text-inherit [&>[type=button]]:cursor-pointer',
	tagLabel: 'mr-1',
};
