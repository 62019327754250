<script lang="ts" setup>
	import HttpClient from '@/kernel/httpClient';
	import TheHeader from '@/ui/Layout/TheHeader.vue';
	import TheSidebar from '@/ui/Navigation/TheSidebar.vue';
	import { useLayout } from '~/app/common/composables/use-layout';

	const online = useOnline();
	const layoutProps = useLayout();
	const sideBarOpened = ref(false);

	const isDefaultNetwork = computed(() => HttpClient.getEnvironment() === import.meta.env.VITE_APP_DEFAULT_ENV);

	const keys = useMagicKeys();
	whenever(keys.ctrl_b, () => {
		sideBarOpened.value = !sideBarOpened.value;
	});

	const route = useRoute();
	watch(route, () => {
		if (sideBarOpened.value) {
			sideBarOpened.value = false;
		}
	});
</script>

<template>
	<div class="flex h-screen overflow-hidden bg-gray-100" @keydown.esc="sideBarOpened = false">
		<TheSidebar :opened="sideBarOpened" @close="sideBarOpened = false" />

		<div class="flex w-0 flex-1 flex-col overflow-y-auto">
			<div v-if="!online" class="w-full bg-red-600">
				<div class="px-3 py-2 sm:px-6 lg:px-8">
					<p class="font-medium text-white">You appears to be offline. Please check your network.</p>
				</div>
			</div>

			<div v-if="!isDefaultNetwork" class="w-full bg-red-600">
				<div class="px-3 py-2 sm:px-6 lg:px-8">
					<p class="font-medium text-white">
						You are using the
						<strong>{{ HttpClient.getEnvironment() }}</strong>
						network.
					</p>
				</div>
			</div>

			<main class="focus:outline-hidden relative z-0 px-8 pt-10" tabindex="0">
				<div class="space-y-8">
					<TheHeader :title="layoutProps.title" @open-sidebar="sideBarOpened = true" />

					<div class="mx-auto pb-4" :class="{ 'max-w-full': true, 'max-w-7xl': false }">
						<slot />
					</div>
				</div>
			</main>
		</div>
	</div>
</template>

<style>
	main {
		scrollbar-gutter: stable both-edges;
		overflow: auto;
	}
</style>
