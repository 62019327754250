<script lang="ts" setup>
	import SidebarDesktop from '~/ui/Navigation/SidebarDesktop.vue';

	const SidebarMobile = defineAsyncComponent(() => import('~/ui/Navigation/SidebarMobile.vue'));
	const SidebarBackdrop = defineAsyncComponent(() => import('~/ui/Navigation/SidebarBackdrop.vue'));

	defineProps<{
		opened: boolean;
	}>();
</script>

<template>
	<div class="flex h-screen overflow-hidden bg-gray-100" @keydown.esc="$emit('close')">
		<div class="xl:hidden">
			<!-- Backdrop -->
			<Transition
				enter-active-class="duration-200 ease-linear"
				enter-from-class="opacity-0"
				enter-to-class="opacity-100"
				leave-active-class="duration-200 ease-linear"
				leave-from-class="opacity-100"
				leave-to-class="opacity-0"
			>
				<SidebarBackdrop v-if="opened" @click="$emit('close')" />
			</Transition>

			<!-- Sidebar -->
			<Transition
				enter-active-class="transition duration-300 ease-in-out transform"
				enter-from-class="-translate-x-full"
				enter-to-class="translate-x-0"
				leave-active-class="transition duration-300 ease-in-out transform"
				leave-from-class="translate-x-0"
				leave-to-class="-translate-x-full"
			>
				<SidebarMobile v-if="opened" @close="$emit('close')" />
			</Transition>
		</div>

		<div class="hidden xl:flex xl:shrink-0">
			<SidebarDesktop />
		</div>
	</div>
</template>
