<script lang="ts" setup>
	import './app.css';
	import { VueQueryDevtools } from '@tanstack/vue-query-devtools';
	import { useHead } from '@unhead/vue';
	import NotificationList from '~/app/common/components/notification-list.vue';
	import UpdateBanner from '~/app/core/components/update-banner.vue';

	useHead({
		titleTemplate: (title?: string) => (title ? `${title} | FIVB VIS WebManager` : 'FIVB VIS WebManager'),
	});
</script>

<template>
	<div class="font-sans antialiased">
		<component :is="$route.meta.layout || 'div'">
			<RouterView v-slot="{ Component }">
				<Transition name="fade" mode="out-in">
					<component :is="Component" />
				</Transition>
			</RouterView>
		</component>

		<NotificationList />
		<UpdateBanner />
		<VueQueryDevtools />
	</div>
</template>
